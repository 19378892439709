.table {
  min-width: 900px;
  border: #eee;
  border-radius: 8px;
  box-shadow: 5px 10px #eee;
}
td {
text-align: center;
justify-content: center;
align-items: center;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .table {
    min-width: 100px;
    border-collapse: collapse; 
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .table {
    max-width: 770px;
  }
}

@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .table {
    max-width: 770px;
  }
}
