@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
.header {
  /* position: fixed; */
  z-index: 1000;
  /* top: 110; */
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  min-height: 56px;
  justify-content: center;
}
.footer{
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer p {
  text-align: center;
  width: 80%;
}
.logot {
  height: 20px;
  padding-right: 10px;
}
.header > nav {
  padding-right: 2em;
  padding-left: 2em;
}
.dropdown {
  color: rgb(0, 0, 0);
}
.navbar-nav {
  display: flex!important;
  justify-content: flex-end;
}
.schoolName,
.schoolId,
.name,
.balance {
  margin-right: 15px;
  white-space: nowrap;
  color: #333;
}

.schoolName .schoolId .name .balance {
  color:#386df9;
  font-weight: 500;
}

.userInfo span {
  margin-right: 10px;
}

.logo {
  width: 70px;
  height: 70px;
}

.topbar {
  color:#383838;
  height: 50px;
  font-size: small;
}
  
.mainHeadMenuUl a {
  transition: 0.3s;
  color: #333;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 10px 15px;
}

.mainHeadMenuUl a:hover {
  color: #333;
  background-color: #eee;
  border-radius: 8px;
}

.mainHeadMenuUl .active {
  /* color: #0088e1; */
  display: block;
  color: #333;
  background-color: #eee;
  border-radius: 8px;
  transition: 0.6s ease-out;
  /* transform: translateX(20px); */
}

@media screen and (max-width: 1200px) {
  .schoolName {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .schoolId {
    display: none;
  }
  
}

@media screen and (max-width: 800px) {
  .name {
    display: none;
  }
  .arrow {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .balance {
    display: none;
  }
  .arrow {
    display: none;
  }
}
