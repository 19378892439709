.mainMenu {
  position: fixed;
  z-index: 3;

  overflow-x: hidden;
  overflow-y: auto;
  /* overflow: hidden; */
  min-width: 300px;
  max-width: 300px;
  height: 90%;
  margin-top: 128px!important;
  padding: 10px;
top: 18px;
  background: #ffff;
  border: 2px solid #eee;
}

.close {
  display: none;
}

.mainMenuUl {
  margin-top: 50px;
}

.mainMenuUl li {
  margin-bottom: 30px;
  margin-left: 5%;
}

.mainMenuUl li a {
  transition: 0.3s;

  color: #333;
  font-weight: 400;

  font-size: 1.2rem;
}

.mainMenuUl li a:hover {
  color: #333;
  background-color: #eee;
  border-radius: 8px;
}

.mainMenuUl li .active {
  display: block;
  color: #333;
  background-color: #eee;
  border-radius: 8px;
  transition: 0.6s ease-out;
  transform: translateX(20px);
}

.lang {
  width: 150px;
  margin-top: 30px;
  margin-left: 50px;
}

.arrow {
  position: absolute;
  right: 0;

  border: none;
}

.open {
  position: absolute;
  left: 0;

  border: none;
}

