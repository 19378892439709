.select {
  box-sizing: border-box;
  width: 100%;
  padding: 7px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

.thead {
  text-align: center;
  vertical-align: middle !important;
}

.kontingent {
  background-color: pink;
}

.nameBooks {
  background-color: royalblue;
}

.table {
  text-align: center;
  vertical-align: middle !important;

  font-size: 0.75rem;
}

.nextPrev {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.download > button {
  margin-right: 10px;
  margin-bottom: 20px;
}

.verify__button {
  display: flex;
  justify-content: space-between;
}
