.tabs,
thead {
  display: table-row-group;
}

@media (min-width: 375px) and (max-width: 400px) { 
  .tabs,
  thead{
    margin: 0 auto;
  }
}