.count {
  width: 100px;
  height: 50px;
  padding: 0 10px;
}

.gradeBox button {
  margin-top: 10px;
  margin-left: 20px;
}

.gradeBox {
  padding-top: 20px;
  padding-bottom: 20px;

  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.wrapper {
  margin-top: 40px;
}

.bookItem {
  padding-top: 5px;
  padding-bottom: 10px;

  border-top: #d8d8d8 solid 1px;
}

.bookslist {
  overflow-y: scroll;

  height: 600px;
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pdfButton {
  float: right;
}

.pdfButton svg {
  color: red;
}

.select {
  width: 300px;
}

.bookName {
  width: 300px;
}

.pdfButton {
  float: right;

  margin: 0 10px 10px 0;

  border: none;
}
