@media screen and (min-width: 1240px) {
    .only-mobile {
      display: none!important;
    }
  }

  @media screen and (min-width: 340) and (max-width: 400) {
    .logo {
      display:none
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex!important;
      flex-basis: auto;
  }
  }
  @media screen and (min-width: 370px) and (max-width: 400px){
    .phone-numbers {
      position: relative;
      left: 4px;
      margin-top: 45px;
      margin-left: 20px;
      font-weight: 600;
      display: none;
      font-size: 20px;
  }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
      display: flex!important;
      justify-content: flex-end;
      flex-basis: auto;
  }
  }
  .phone-numbers {
    font-size: 16px!important;
  }