.select {
  box-sizing: border-box;
  width: 100%;
  padding: 7px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

.wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 48%;
}
