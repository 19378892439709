.home-title {
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
    font-weight: 700;
    color:'#333';
    font-size: 48px;
}
.phone-numbers {
    position: relative;
    left: 40px;
    margin-top: 45px;
    margin-left: 110px;
    font-weight: 600;
    font-size: 28px;
}
.phone-numbers-container {
    text-align: center;
    font-size: 2rem;
    height: 60vh;
    margin-right: 35px;
}
.whatsapp {
    margin-top: 20px;
    font-size: 27px;
    color: #183153;
}
.numbers {
    font-size: 27px;
    color:#183153;
}
.mail {
   margin-left: 110px;
   font-size: 27px;
   color:#183153;
}
.icon-size {
    width: 30px;
    height: 30px;
    position: relative;
    bottom: 5px;
}
.social-networks {
    margin-top: 50px;
    font-weight: 700;
    color:#333;
    position: relative;
    bottom: '5px';
}
.social-networks-name {
    font-size: 24px;
    color:#183153;
}
.subscribe-text {
    margin-top:20px;
    color:#183153;
    font-size: 24px;
    width: 400px;
}
.google-form-button {
    width: 500px;
    display: block;
    margin: 0 auto;
    position: relative;
    bottom:240px;
    font-size: 30px;
    font-weight: bold;
    border-radius: 8px;
}

@media screen and (min-width: 370px) and (max-width: 400px) {
    .home-title {
        text-align: center;
        margin-top: 10px;
        margin-left: 0;
        font-weight: 700;
        color:'#333';
        font-size: 28px;
    }
    .phone-numbers {
        position: relative;
        left: 4px;
        margin-top: 45px;
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
    }
    .numbers {
        font-size: 24px;
    }
    .whatsapp {
        font-size: 24px;
    }
    .mail {
        margin-left: 20px;
        font-size: 24px;
    }
    .social-networks {
        margin-top: -12rem;
        margin-left: 24px;
        font-size: 20px;
    }
    .social-networks-name {
        font-size: 20px;
    }
    .icon-size {
        margin-left: 20px;
        margin-top: 10px;
    }
    .google-form-button {
        width: 500px;
        margin: auto;
        position: relative;
        top: 100px;
        font-size: 30px;
        font-weight: bold;
        border-radius: 8px;
    }
    .subscribe-text {
        font-size: 20px;
        width: 350px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 400px) {
    .social-networks {
        margin-top: -12rem;
        margin-left: 24px;
        font-size: 20px;
    }
  }
  /* @media screen and (min-width: 390px) {
    .social-networks {
        margin-top: -19rem;
        margin-left: 24px;
        font-size: 20px;
    }
  } */
  @media screen and (min-width: 410px) and (max-width: 420px) {
    .home-title {
        text-align: center;
        margin-top: 10px;
        margin-left: 0;
        font-weight: 700;
        color:'#333';
        font-size: 28px;
    }
    .phone-numbers {
        position: relative;
        left: 4px;
        margin-top: 45px;
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
    }
    .numbers {
        font-size: 24px;
    }
    .whatsapp {
        font-size: 24px;
    }
    .mail {
        margin-left: 20px;
        font-size: 24px;
    }
    .social-networks {
        margin-top: -12rem;
        margin-left: 24px;
        font-size: 20px;
    }
    .social-networks-name {
        font-size: 20px;
    }
    .icon-size {
        margin-left: 20px;
        margin-top: 10px;
    }
    .google-form-button {
        width: 500px;
        margin: auto;
        position: relative;
        top: 100px;
        font-size: 30px;
        font-weight: bold;
        border-radius: 8px;
    }
    .subscribe-text {
        font-size: 20px;
        width: 350px;
    }
  }