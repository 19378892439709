.up {
  position: fixed;
  z-index: 1000;
  right: 40px;
  bottom: 40px;

  border: none;
}

.hidden {
  display: none;
}

.componentTable {
  min-width: 1480px;
  margin-top: 180px;
  margin-right: 20px;
  margin-left: 320px;
  padding: 10px;

  border-radius: 10px;
  background: #fff;
}

.menuClose {
  margin-right: auto;
  margin-left: auto;
}

.navButton {
  position: fixed;
  top: 160px;
  left: 5px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .componentTable {
    min-width: 100px !important;
  }
}
