.last-news-title {
    font-weight: bold;
    font-size: 28px;
    margin: 20px;
}
.home-title {
    margin-top: 80px;
}
.my-card {
    box-shadow: 5px 10px #ccc;
}
.news-preview {
    position: relative;
    width: 500px;
    left: 25%;
}
.news-details-title {
    text-align: center;
    font-weight: bold;
    font-size: 56px;
}
.news-details-btn {
    font-size: 20px;
}
.news-details-description {
    font-size: 24px;
    font-weight: 500;
    margin-top: 20px;
}

@media (min-width: 375px) and (max-width: 400px) {
    .home-title {
        font-size: 20px;
    }
    .last-news-title {
        font-weight: 500;
        font-size: 20px;
        margin: 5px;
    }
    .my-card {
        width: 350px;
        margin-top: 20px;
        box-shadow: 5px 10px #eee;
    }
    .my-card-title {
        font-size: 22px;
        text-align: center;
    }
    .my-card-description {
        font-size: 18px;
        font-weight: lighter;
        padding: 5px;
        text-align: center;
    }
    .my-card-btn {
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 85px;
    }
    .news-details-title {
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }
    .news-preview {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }
}