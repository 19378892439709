.resetIcon {
  position: absolute;
  top: 32px;
  right: 2px;

  display: flex;

  color: #888;
  border-radius: 20px;
}

.resetIconNoLabel {
  position: absolute;
  top: 1px;
  right: 2px;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 48%;
}

.wrapper {
  position: relative;

  margin-bottom: 10px;
}

.label {
  white-space: nowrap;
}
