.select {
  box-sizing: border-box;
  width: 200px;
  padding: 7px 8px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

input:invalid {
  background-color: ivory;
  border: none;
  outline: 2px solid red;
  border-radius: 5px;
}

.line {
  width: 100%;
  height: 1px;
  margin-top: 3px;

  background: #a8bccf;
}

.bookItem {
  position: relative;

  padding: 15px 25px;

  border-bottom: 1px solid #a8bccf;
}

.total {
  width: 80px;
  margin-right: 5px;

  border-radius: 5px;
  background: none;
}

.changeButton {
  color: black;
  border-color: #90909a;
}

.changeButton:focus {
  color: white;
  background-color: #90909a;
}

.closeIcon {
  position: absolute;
  top: 6px;
  right: 5px;

  cursor: pointer;
}

.bookList {
  overflow: auto;

  height: 60vh;
}

.booksActions {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0;
}

.nextBooks {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 45%;

  border: none;
}

.active {
  cursor: pointer;
}

.disable {
  cursor: default;
}

.filterBlock {
  background-color: #fafafa;
}

.year {
  position: absolute;
  top: 15px;
  right: 20px;
}

.btnAdd {
  position: absolute;
  right: 15px;
  bottom: 20px;

  background: "#05c46b";
}

.search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;

  margin: 3px;
}

.search input {
  width: 200px;
  margin: 10px 0;
}

.searchSchool {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.select {
  box-sizing: border-box;
  width: 100%;
  width: 120px;
  padding: 8px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .year {
    position: static;
  }
}
