.bookCard {
  display: flex;
  padding: 15px;
  /* flex-direction: row/; */

  /* justify-content: space-between; */
}

.bookInfo {
  flex: 50%;
}

.bookAction {
  display: flex;
  align-items: center;
  flex: 50%;
  justify-content: space-between;
  
}

.btnAdd {
  background-color: #05c46b;
}
