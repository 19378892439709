.print {
  display: none;

  margin-bottom: 20px;
}

.print p {
  padding-left: 30px;

  text-align: start;

  font-size: 16px;
  font-weight: normal;
}

.print * {
  border: none;
}

.title {
  margin-bottom: 20px;

  text-align: center;
}

@media print {
  body {
    overflow: visible;

    background: white;
  }

  .print {
    display: table-row-group;

    margin-bottom: 20px;
  }

  @page {
    size: landscape;

    margin: 10mm;

    color: white;
    background: white;
  }
}
