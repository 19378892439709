@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
html,
body {
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.componentTableMarket {
  margin-top: 120px;
  padding: 10px;
}

.carousel {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.noWrap {
  white-space: nowrap;
}

.logo {
  width: 50px;
  text-align: center;
}

.imag {
  object-fit: cover;

  height: 600px;

  border-radius: 10px;
}

.logo img {
  max-width: 120px;
  margin: 0 auto;
}

.asterix {
  margin-left: 2px;

  color: red;

  font-weight: bold;
}

.homeForm {
  margin: auto;
  margin-top: 30px;
}

.moduleBtns {
  display: flex;
  justify-content: space-between;

  margin-top: 5px;
}
.Toastify__toast-body {
  font-size: 30px !important;
}
.Toastify__toast-container {
  width: 500px !important;
}