.searchIcon {
  position: absolute;
  top: 50px;
  right: 20px;

  cursor: pointer;
}

.searchPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formFullWidht {
  width: 100%;
}

.formGroup {
  width: 48%;
}

.formGroup:first-child {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.addTeacher {
  float: right;

  margin: 10px;
}
@media (min-width: 375px) and (max-width: 400px) {
  .addTeacher {
    float: left;
  }
}