.conainer-bookDetail {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;
  padding: 2px;
  border-radius: 24px;
  overflow: hidden;
  line-height: 1.6;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 10%;
  border-radius: 22px;
  color: #ffffff;
  overflow: hidden;
  background: #ffffff;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3;
  z-index: 1;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .para {
  z-index: 1;
  opacity: 0.8;
  font-size: 18px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.card1::before {
  content: "";
  position: absolute;
  height: 160%;
  width: 160%;
  border-radius: inherit;
  background: #0a3cff;
  background: linear-gradient(to right, #0a3cff, #0a3cff);
  transform-origin: center;
  animation: moving 4.8s linear infinite paused;
  transition: all 0.88s cubic-bezier(0.23, 1, 0.32, 1);
}

.card1:hover::before {
  animation-play-state: running;
  z-index: -1;
  width: 60%;
}

.card1:hover .content .heading,
.card1:hover .content .para {
  color: #000000;
}

.card1:hover {
  box-shadow: 0rem 6px 13px rgba(10, 60, 255, 0.1),
    0rem 24px 24px rgba(10, 60, 255, 0.09),
    0rem 55px 33px rgba(10, 60, 255, 0.05),
    0rem 97px 39px rgba(10, 60, 255, 0.01), 0rem 152px 43px rgba(10, 60, 255, 0);
  scale: 1.05;
  color: #000000;
}

.btn {
  z-index: 1;
}

@keyframes moving {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  border: none;
  background: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}

.lable {
  margin-top: 1px;
  font-size: 19px;
  line-height: 22px;
  color: #056dfa;
  letter-spacing: 1px;
  z-index: 1;
}

.lable:hover {
  color: #052afa;
  transition: 300ms;
}

.button:hover .svg-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-8deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(8deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
@media (max-width: 460px) {
  .card1 {
    width: 90%;
  }
}
