@media (min-width: 375px) and (max-width: 400px) {
    .tabs {
        font-size: 24px;
        align-items: center;
        justify-content: center;
    }
    .tab {
        position: relative;
        left: 150%;
    }
    .report-title {
        text-align: center;
    }
}
.title {
    text-align: center;
}