.imgWrap {
  overflow: hidden;

  box-sizing: border-box;
  width: 170px;
  height: auto;

  box-shadow: 0 0 7px 1px #363636;
}

.imgWrap img {
  width: 100%;
  object-fit: cover;
}

.countInput {
  min-height: 50px;
}

.item {
  box-sizing: border-box;
  padding: 20px;

  border: 1px solid #eee;
  background: #fff;
}

.btn {
  box-sizing: border-box;
  min-width: 50px;
}
