.basket {
  position: fixed;
  top: 0;
  /* bottom: 0; */
  right: 0;
  overflow: scroll;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 145px;
  height: 85%;
  padding: 10px 20px;

  transition: 2s;

  background: #fff;
  box-shadow: 0 0 10px 10px #ccc;
}

.basketOpen {
  display: none;
  position: absolute;
  top: 0;
  right: -100%;

  /* display: inline-flex; */
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 100vh;
  padding: 150px 20px;

  transition: 2s;

  background: #fff;
  box-shadow: 0 0 10px 10px #ccc;
}

.openBtn {
  position: fixed;
  top: 180px;
  right: 0;

  padding: 15px 15px;

  text-transform: uppercase;

  color: #fff;
  border: none;
  background: #dc3545;

  font-size: 0.75rem;
}

.delBtn {
  width: 25px;
  height: 25px;

  color: #000;
  border: none;
  background: #ccc;
}

.countInput {
  max-width: 120px;
}

.countForm {
  display: flex;
  flex-direction: row;
}

.cartTitle  {
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 32px;
  margin-top: 20px;
  letter-spacing: 3px;
}
.orderBtn {
  margin:0 auto;
  display: block;
  padding: 10px;
  font-size: 18px;
}
.navigation {
  margin-left: 45%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 370px) and (max-width: 400px) {
  .openBtn {
    position: fixed;
    top: 744px;
    right: 387px;
    padding: 25px 20px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    background: #dc3545;
    font-size: 0.75rem;;
  }
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    margin-bottom: 15px;
    height: 41px!important;
}
.input {
  height: 48px!important;
  position: relative;
  right: 8px;
  margin-bottom: 11px;
}
.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  height: 48px;
  margin-top: 10px!important;
}
.countForm {
  display: flex;
  flex-direction: column;
}
}