.showPasswordBtn {
  position: absolute;
  top: 60%;
  right: 0;
}

.showPasswordBtnDis {
  position: absolute;
  top: 45%;
  right: 0;
}

.d10 {
  margin-top: 8%;
}

.wrapper {
  width: 30%;
  margin: auto;
  margin-top: 2%;
  padding: 40px 20px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px #ccc;
}

.wrapperRestore {
  width: 60%;
  margin: auto;
  margin-top: 5%;
  padding: 40px 20px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 20px #ccc;
}

.profileWrapper {
  display: flex;
  flex-direction: column;

  width: 90%;
  margin-left: 20px;

  font-size: 1.2rem;
}

.profileWrapper p {
  font-weight: bold;
}

.profileWrapper span {
  margin-left: 10px;

  color: black;

  font-weight: normal;
}

.buttonsProfile {
  margin: 20px 0;
}

.buttonsProfile button {
  margin-right: 20px;
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    width: 80%;
  }

  .wrapperRestore {
    width: 80%;
    height: 80%;
  }
}
