td.grade {
    background-color: #fff;
}
.table-responsive{
   max-height: 55vh; 
}
td.grade-result {
    text-align: left;
}
.learning-language {
    background-color: #e9ecef;
}
.language {
    color: #0088e1;
    font-weight: 700;
    font-size: 18px;
}
.table-responsive thead{
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: -1px;
    background-color: rgb(202, 202, 202);
  }
@media (min-width: 375px) and (max-width: 400px) {
    .learning-language {
        display: none;
    }
}
@media print{
    @page {
        size: landscape;
    }
    #d10PrintDiv {
        max-width: 90%;
    }
    #pdfdiv {
        padding: 30px;
    }
    table {
        font-size: 10px;
    }
    #pdftable {
        width: 50%;
    }
    .no-print {
        display: none;
    }
    .table-responsive{
        overflow: auto;
     }
}