body {
  margin: 0;

  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    "source-code-pro",
    "Menlo",
    "Monaco",
    "Consolas",
    "Courier New",
    monospace;
}

:root {
  --red: #852727;
  --mobule: 450px;
  --planshet: 950px;
  --notebook: 1350px;
  --standard: 1920px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .componentTable {
    min-width: 100px;
  }
}