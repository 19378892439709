.back-link {
    position: relative;
    top: 20px;

}
@media (min-width: 375px) and (max-width: 400px) {
    .book-fond-title {
        text-align: center;
        margin: 5px;
    }
    .add-books-btn {
        float: left;
        position: relative;
        top: 20px;
        left: 70px;
        margin: 8px;
    }
    .book-fond-table {
        margin-top:40px;
    }
    .class-action-title {
        text-align: center;
        font-size: 20px;
    }
    .class-action-data {
        text-align: center;
        font-weight: lighter;
    }
    .class-action-tab {
        font-size: 18px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }
    .class-action-tab-data {
        position: relative;
        left: 50px;
    }
    .back-link {
        position: relative;
        top: 20px;
        left: 20px;
    }
    .teacher-book-title {
        text-align: center;
        font-size: 20px;
    }
    .teacher-book-data {
        font-size: 18px;
        font-weight: lighter;
    }
    .video-title {
        text-align: center;
    }
}