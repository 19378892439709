.card {
  margin-bottom: 2em;

  text-align: center;
}

.videoItemTitle {
  margin-top: 0.7em;
}

@media (min-width: 375px) and (max-width: 400px) { 
  .videoItemTitle {
    text-align: center;
  }
  .card > iframe {
    width: 350px;
  }
}