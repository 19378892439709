@media (min-width: 375px) and (max-width: 400px) {
    .search-books{
        position: relative;
        top: 30px;
        width: 100%;
        bottom: 20px;
    }
    .select {
        width: 100%;
    }
}

@media (min-width: 375px) and (max-width: 400px) {
    table {
      border: 0;
    }
    table thead {
      display: none;
    }
    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #333;
    }
    table td {
      display: block;
      text-align: right;
      font-size: 13px;
      border: 2px solid #333;
    }
    table td:last-child {
      border-bottom: 2px solid #333;
    }
    table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
    }
    .books-total {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 8px;
        /* margin-right: 5px; */
        border-radius: 0px;
        background: none;
    }
    .my-column {
        display: flex;
        flex-direction: column;
    }
  }