.button {
  min-width: 120px;
}

.delete {
  min-width: 40px;

  border: none;
}

.search {
  border: none;
  background: #1D5881;
}

.download {
  min-width: auto;

  border: none;
}
