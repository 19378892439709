.cartTable {
  width: 100%;
}
.orderTitle {
  position: relative;
  left: 40%;
  padding: 20px;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 5px;
}
table,
th,
td {
  border: 1px solid #eee;
  text-align: center;
}
