.line {
  width: 100%;
  height: 1px;
  margin-top: 3px;

  background: #a8bccf;
}

.bookItem {
  position: relative;

  padding: 15px 25px;

  border-bottom: 1px solid #a8bccf;
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pdfButton {
  float: right;
}

.pdfButton svg {
  color: red;
}

.bookslist {
  overflow-y: scroll;

  height: 600px;
}
